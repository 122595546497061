import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from "react-router-dom";

import { MainChat } from './components/pages/MainChat';
import { SecondChat } from './components/pages/SecondChat';
import './App.css';

const App = () => {
  const basePath = '/garageEurorepar_dev';
  
  return (
    <Router basename={basePath}>
      <div className="app">
        <nav className="navigation">
          <ul>
            <li><Link to={`${basePath}/`}>Assistant Principal</Link></li>
            <li><Link to={`${basePath}/second-chat`}>Second Assistant</Link></li>
          </ul>
        </nav>

        <Routes>
          <Route path="/" element={<MainChat />} />
          <Route path="/second-chat" element={<SecondChat />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
