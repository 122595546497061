import React, { useEffect, useState, useRef } from "react";
import "../App.css";
import { RetellWebClient } from "retell-client-js-sdk";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://chat.smartofficeassist.com/garageEurorepar_dev/api';

const createUniqueId = () =>
  `msg-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

const createMessage = (role, content) => ({
  role,
  content,
  timestamp: new Date(),
  id: createUniqueId(),
});

export const ChatInterface = ({ agentId, title }) => {
  const [isCalling, setIsCalling] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isAgentSpeaking, setIsAgentSpeaking] = useState(false);
  const chatEndRef = useRef(null);
  const [conversationStartTime, setConversationStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const retellWebClient = useRef(new RetellWebClient());
  const lastUserMessageTime = useRef(0);
  const lastAgentMessageTime = useRef(0);

  useEffect(() => {
    let intervalId;
    if (conversationStartTime) {
      intervalId = setInterval(() => {
        const currentTime = Date.now();
        setElapsedTime(
          Math.floor((currentTime - conversationStartTime) / 1000)
        );
      }, 1000);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [conversationStartTime]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleCallStart = () => {
    console.log("Call started");
    setMessages((prev) => [
      ...prev,
      createMessage("agent", "Call started - You can speak now"),
    ]);
  };

  const handleCallEnd = () => {
    console.log("Call ended");
    setIsCalling(false);
    setMessages((prev) => [...prev, createMessage("agent", "Call ended")]);
  };

  const handleAgentStartTalking = () => {
    console.log("Agent started talking");
    setIsAgentSpeaking(true);
  };

  const handleAgentStopTalking = () => {
    console.log("Agent stopped talking");
    setIsAgentSpeaking(false);
  };

  const toggleConversation = async () => {
    if (isCalling) {
      setIsCalling(false);
      setConversationStartTime(null);
      setElapsedTime(0);
      retellWebClient.current.stopCall();
    } else {
      try {
        const response = await registerCall(agentId);
        if (response.access_token) {
          retellWebClient.current.startCall({
            accessToken: response.access_token,
          });
          setIsCalling(true);
          setConversationStartTime(Date.now());
        }
      } catch (error) {
        console.error("Error starting call:", error.message || error);
      }
    }
  };

  const handleButtonClick = (text) => {
    if (isCalling) {
      const newMessage = createMessage("user", text);
      setMessages((prev) => [...prev, newMessage]);
      sendMessageToAgent(text);
    }
  };

  useEffect(() => {
    const client = retellWebClient.current;

    client.on("update", (update) => {
      if (!update?.transcript || !Array.isArray(update.transcript)) return;

      const transcriptItems = update.transcript;
      const lastItem = transcriptItems[transcriptItems.length - 1];
      if (!lastItem?.content || !lastItem?.role) return;

      setMessages((prevMessages) => {
        const currentTime = Date.now();
        const isUserRole = lastItem.role === "user";

        if (
          (isUserRole && currentTime - lastUserMessageTime.current <= 3000) ||
          (!isUserRole && currentTime - lastAgentMessageTime.current <= 1000)
        ) {
          return prevMessages;
        }

        if (isUserRole) lastUserMessageTime.current = currentTime;
        else lastAgentMessageTime.current = currentTime;

        const lastRoleMessage = [...prevMessages]
          .reverse()
          .find((msg) => msg.role === lastItem.role);

        if (!lastRoleMessage) {
          return [...prevMessages, createMessage(lastItem.role, lastItem.content)];
        }

        if (lastRoleMessage.content !== lastItem.content) {
          return prevMessages.map((msg) =>
            msg.id === lastRoleMessage.id
              ? createMessage(lastItem.role, lastItem.content)
              : msg
          );
        }

        return prevMessages;
      });
    });

    client.on("call_started", handleCallStart);
    client.on("call_ended", handleCallEnd);
    client.on("agent_start_talking", handleAgentStartTalking);
    client.on("agent_stop_talking", handleAgentStopTalking);

    return () => {
      client.removeAllListeners();
    };
  }, []);

  const registerCall = async (agentId) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/create-web-call`, {
        agent_id: agentId,
      });
      if (!response.data || !response.data.access_token) {
        throw new Error("Invalid response from server");
      }
      return response.data;
    } catch (error) {
      console.error("Error registering call:", error.message || error);
      throw error;
    }
  };

  const sendMessageToAgent = async (message) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/agent-webhook`, {
        agent_id: agentId,
        message,
      });
      console.log("Message sent to agent:", response.data);
    } catch (error) {
      console.error(
        "Error sending message:",
        error.response?.data || error.message || error
      );
    }
  };

  return (
    <div className="app-container">
      {conversationStartTime && (
        <div className="conversation-timer">
          Durée : {formatTime(elapsedTime)}
        </div>
      )}
      <div className="header">
        <h1>{title}</h1>
        <button
          onClick={toggleConversation}
          className={isCalling ? "stop-btn" : "start-btn"}
        >
          {isCalling ? "Stop" : "Start"}
        </button>
      </div>
      <div className="chat-container">
        <div className="messages">
          {messages.map((message) => (
            <div key={message.id} className={`message ${message.role}`}>
              <div>{message.content}</div>
            </div>
          ))}
          {isAgentSpeaking && (
            <div className="typing-indicator">Agent is speaking...</div>
          )}
          <div ref={chatEndRef} />
        </div>
      </div>
      <div className="content">
        <div className="garage-info">
          <h2>Garage Eurorepar</h2>
          <p>
            Bienvenue chez Eurorepar, votre partenaire de confiance pour toutes
            vos réparations automobiles.
          </p>
          <ul>
            <li>Adresse : Saint-François</li>
            <li>Téléphone : +590 690 00 00 00</li>
            <li>Email : contact@eurorepar.fr</li>
          </ul>
        </div>
        <div className="assistant-features">
          <h3>Ce que notre assistant peut faire :</h3>
          <button onClick={() => handleButtonClick("Demande de devis rapide")}>
            Demande de devis rapide
          </button>
          <button
            onClick={() =>
              handleButtonClick("Prise de rendez-vous pour réparation")
            }
          >
            Prise de rendez-vous pour réparation
          </button>
          <button
            onClick={() =>
              handleButtonClick(
                "Informations sur l'état d'un véhicule en réparation"
              )
            }
          >
            Informations sur l'état d'un véhicule en réparation
          </button>
          <button
            onClick={() => handleButtonClick("Réclamations et support client")}
          >
            Réclamations et support client
          </button>
        </div>
      </div>
    </div>
  );
};
